// JS Goes here - ES6 supported
import "./css/main.scss";
import Swal from 'sweetalert2'
import 'particles.js'


/* contact forms handling */
/**
 * look for forms on page
 * prevent default behavior
 * make a post request to nodya centralized form managment
 * handle UI feedback on error and success
 * disable form for 2 days if form was submited succesfully
 */
function initMobileMenu(){
  let toggle = document.getElementById('menuToggle')
  let menu =document.getElementById('mainMenu')
  toggle.addEventListener('click', (e) => {
    if(menu.classList.contains('closed')) menu.classList.remove('closed')
    else menu.classList.add('closed')
  })
}
function highlightRequiredInputs(){
    for(let input of document.querySelectorAll('input[required]')){
        input.classList.add('border-yellow-400')

        let requiredInputs = function(e){
            input.classList.remove('border-yellow-400')
            input.removeEventListener('change', requiredInputs)
        }
        input.addEventListener('change', requiredInputs)
    }
}
function handleSubmission(e){
    e.preventDefault()

    var form = getForm()

    if(!form.checkValidity()){
        Swal.fire({
            icon:"warning",
            title: 'Erreur',
            text: "Veuillez remplir les champs requis",
            confirmButtonText: 'Ok',
        })
        
        highlightRequiredInputs()
        return
    }

    submitForm(form)
}
function getSubmitButton(){
  return document.querySelector('#submitForm')
}
function submitForm(form){
    // prepare request
    var req = new XMLHttpRequest();
    let url = form.getAttribute('action')

    let submitButton = getSubmitButton()

    // prepare form data
    var formData = new FormData(form)
    var object = {};
    formData.forEach(function(value, key){
        object[key] = value;
    });
    var json = JSON.stringify(object);

    req.open("POST", url)
    req.setRequestHeader('Content-Type', form.getAttribute('enctype'))

    // set loading text
    if(submitButton != null){
      submitButton.value = 'Envoie en cours...'
      disableForm()
    }
    let failedTimeout = setTimeout(()=>{
        req.abort()
        Swal.fire({
            icon:"error",
            title: 'Une erreur est survenue',
            text: "Le serveur a mis trop de temps à répondre, et nous avons annulé l'envoie du formulaire... Veuillez vérifier votre connexion à internet, ou réessayez plus tard...",
            confirmButtonText: 'Ok',
        })
        if(submitButton != null){
          submitButton.value = 'Réessayer'
          enableFormElmt(submitButton)
          for(let input of getInputs()){
            enableFormElmt(input)
          }
        }
    }, 15000)

    // error handling
    req.onError = function(e) {
        clearTimeout(failedTimeout)
        // console.log('error')
        if(submitButton != null){
            submitButton.value = 'Réessayer'
            submitButton.disabled = false
            submitButton.classList.add('text-blue-800')
            submitButton.classList.add('cursor-pointer')
            submitButton.classList.remove('text-gray-400')
            submitButton.classList.remove('cursor-not-allowed')
        }
        Swal.fire({
            icon:"error",
            title: 'Une erreur est survenue',
            text: "Nous avons rencontré une erreur sur le réseau... Merci de réessayer plus tard, ou de nous contacter par un autre moyen (mail, téléphone, linkedin)",
            confirmButtonText: 'Ok',
        })
    }

    // success handling
    req.onload = function(e) {
        clearTimeout(failedTimeout)
        // console.log('OK!')
        localStorage.setItem('ndy_hasSubmited', Date.now())
        Swal.fire({
            icon:"success",
            title: 'Merci.',
            text: "Votre demande de contact a bien été envoyée. Nous reviendrons vers vous très prochainement.",
            confirmButtonText: 'Ok',
        })
        disableForm()
        getSubmitButton().value = "Votre demande de contact a bien été envoyée."
    }

    req.send(json);
}
function initForm(){
    let hasSubmited = localStorage.getItem('ndy_hasSubmited')

    let submitButton = getSubmitButton()

    if(Date.now() - hasSubmited < 1000 * 60 * 60 * 24 * 1){ // if last submission was 1 days ago or less
        getSubmitButton().value = "Votre demande de contact a bien été envoyée."
        disableForm()
        return
    }

    if(submitButton != null){
        submitButton.addEventListener('click', handleSubmission)
    }
}
function disableFormElmt(elmt){
  elmt.disabled = true
  elmt.classList.remove('text-blue-800')
  elmt.classList.remove('cursor-pointer')
  elmt.classList.add('text-gray-400')
  elmt.classList.add('cursor-not-allowed')
  elmt.classList.add('bg-gray-200')
}
function enableFormElmt(elmt){
  elmt.disabled = false
  elmt.classList.add('text-blue-800')
  elmt.classList.add('cursor-pointer')
  elmt.classList.remove('text-gray-400')
  elmt.classList.remove('cursor-not-allowed')
  elmt.classList.remove('bg-gray-200')
}
function getForm(){
  return document.getElementById('contactForm')
}
function getInputs(){
  return getForm().querySelectorAll("input")
}
function disableForm(){
  disableFormElmt(getSubmitButton())
  getSubmitButton().classList.add('text-sm')
  getSubmitButton().classList.remove('text-lg')
  for(let input of getInputs()){
    disableFormElmt(input)
  }
  return
}


/* no cookies notice handling */
/**
 * show a cookie notice if the user visits for first time today
 * on ok click, save current timestamp to localstorage
 * dont show notice again after this for one day
 */
function okCookieBtn(){
  return document.getElementById('ndy-okCookies')
}
function cookieNotice(){
  return document.getElementById('ndy-cookieNotice')
}
function hideNoCookieNotice(){
  if(!cookieNotice().classList.contains('translate-y-full'))
    cookieNotice().classList.add('translate-y-full')
}
function showNoCookieNotice(){
  if(cookieNotice().classList.contains('translate-y-full'))
    cookieNotice().classList.remove('translate-y-full')
}
var handleNotice = function(e){
  localStorage.setItem('ndy_hasSeenNotice', Date.now())
  hideNoCookieNotice()
}
function noCookieNotice(){
  let hasSeenNotice = localStorage.getItem('ndy_hasSeenNotice')
  
  if(Date.now() - hasSeenNotice > 1000 * 60 * 60 * 24 * 1){ // if last seen was more than one day
    showNoCookieNotice()
  }
  
  okCookieBtn().addEventListener('click', handleNotice)
}

/**
 * particles
 */
function initParticles(){
    let elmts = document.getElementsByClassName('particleJs')
    console.log(elmts)
    let id=0
    for(let elmt of elmts){
        elmt.id = `particleJs-${id}`
        particlesJS.load(elmt.id, '/assets/particlesjs-config.json');
    }
}

var doubleClickTimeout = null
var handleVideoClick = (videoElmt) => {
  videoElmt.volume = 0
  // on simple click, play/pause
  if(doubleClickTimeout == null){
    doubleClickTimeout = setTimeout(()=> {
      videoElmt.paused ? videoElmt.play() : videoElmt.pause();
      doubleClickTimeout = null;
    }, 250)
  }
  // on doubleClick, requestFullscreen
  else{
    clearTimeout(doubleClickTimeout)
    doubleClickTimeout = null;
    videoElmt.requestFullscreen()
  }
}
document.handleVideoClick = handleVideoClick
/* bootstrap */
initForm()
initMobileMenu()
noCookieNotice()
initParticles()
